<template>
    <h2 class="w-full text-6xl uppercase font-family-century text-center" :class="$attrs.class">
        <slot></slot>
    </h2>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>

</style>