<template>
    <h2 class="w-full text-[clamp(30px,5vw,42px)] font-family-century text-color-black leading-tight">
        <slot></slot>
    </h2>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>

</style>