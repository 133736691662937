<template>
    <section id="contact" class="relative mt-4 mb-4 md:mb-16">
        <div class="container">
            <div class="w-full  flex flex-row flex-wrap">

                <!-- map -->
                <div class="order-2 md:order-2 lg:order-1 w-full md:w-1/2 xl:w-1/3 flex-col flex justify-start items-center mb-8 px-4">
                    <img :src="require('@/assets/images/logo.png')" class="mb-4 max-w-full"/>
                    <iframe :src="$contact.googleMapLinkIframe"
                            style="border:0;" allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                            class="aspect-[16/13] w-full max-w-xs"
                    ></iframe>
                </div>

                <!-- contact -->
                <div class="order-3 md:order-3 lg:order-2 w-full md:w-1/2 xl:w-1/3 flex-col flex justify-start items-center mb-8 px-4 text-center _md:text-left">
                    <section-title-century class="text-center text-[2rem] mb-4" v-html="$contact.title"></section-title-century>


                    <p class="font-family-century text-md uppercase leading-tight mb-8 w-full max-w-sm" v-html="$contact.textFooter"></p>

                    <p class="font-family-century text-lg uppercase leading-tight mb-4 w-full max-w-sm" v-html="$contact.address"></p>

                    <p class="font-family-century text-lg uppercase leading-tight mb-4 w-full max-w-sm" v-html="$contact.phone"></p>

<!--                    <p class="font-family-century text-lg uppercase leading-tight mb-4 w-full max-w-sm" v-html="$contact.program"></p>-->

                    <ul class="flex flex-row justify-center items-center gap-2 mt-4 mb-4">
                        <template v-for="(social, idx) in $contact.social">

                            <template v-if=" idx === 'instagram' ">
                                <li>
                                    <a target="_blank"
                                       :href=" 'https://www.instagram.com/' + $contact.social.instagram"
                                       class="bg-color-wewak text-color-white w-10 h-10 flex justify-center items-center rounded-full text-2xl leading-none"
                                    >
                                        <span class="icon-instagram1 ml-0.5"></span>
                                    </a>
                                </li>
                            </template>
                            <template v-if=" idx === 'email' ">
                                <li>
                                    <a target="_blank"
                                       :href=" 'mailto:' + $contact.social.email "
                                       class="bg-color-wewak text-color-white w-10 h-10 flex justify-center items-center rounded-full text-2xl leading-none"
                                    >
                                        <span class="icon-envelope"></span>
                                    </a>
                                </li>
                            </template>

                        </template>
                    </ul>

                </div>

                <!-- formular -->
                <div class="order-1 lg:order-3 w-full md:w-full xl:w-1/3 flex flex-col justify-start items-center mb-8 px-4">
                    <form @submit.prevent.stop="$sendContact(contactForm)">
                        <div class="w-full flex flex-col mb-4">
                            <section-title-century class="text-center  text-[2rem] " v-html="$contact.titleAppointment"></section-title-century>
                        </div>
                        <div class="w-full flex flex-col mb-4">
                            <label v-html="$contact.titleFieldName"></label>
                            <input type="text" class="bg-gray-100 p-2 rounded-md" v-model="contactForm.name" required/>
                        </div>
                        <div class="w-full flex flex-col mb-4">
                            <label v-html="$contact.titleFieldEmail"></label>
                            <input type="email" class="bg-gray-100 p-2 rounded-md" v-model="contactForm.email" required/>
                        </div>
                        <div class="w-full flex flex-col mb-4">
                            <label v-html="$contact.titleFieldMessage"></label>
                            <textarea type="message" class="bg-gray-100 p-2 rounded-md" v-model="contactForm.message" required></textarea>
                        </div>
                        <div class="w-full flex flex-row justify-center">
                            <button type="submit" class="px-12 py-2 rounded-lg bg-color-wewak uppercase font-family-century" v-html="$contact.titleButtonSend"></button>
                        </div>
                    </form>
                </div>


            </div>

        </div>
    </section>
</template>


<script setup>
import {useData} from "@/helpers/data";
import {useForm} from "@/helpers/form";

import {ref} from "vue";

const $data = useData().get();
const $contact = $data.sections.contact;
const $newsletter = $data.sections.newsletter;

const $sendContact = useForm().sendContact;

const contactForm = ref({
    name : "",
    email : "",
    message : "",
})
</script>


<style lang="scss" scoped>

</style>