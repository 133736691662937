import store from '@/store'

const generateHref = (content) => {
    let {
        name, email, message,
        mailto, subject, template
    } = content;

    subject = encodeURI(subject);

    let body = template.replaceAll('@@@name@@@', name).replaceAll('@@@email@@@', email).replaceAll('@@@message@@@', message);
    body = encodeURI(body);

    return `mailto:${mailto}?subject=${subject}&body=${body}`;
}

export function useForm() {

    return {
        sendContact: (content) => {
            const $data = store.getters.data;

            const $templateContact = $data.template.contact;

            const {name, email, message} = content;

            const anchorEl = document.createElement('a');

            anchorEl.href = generateHref({
                name, email, message,
                mailto: $templateContact.mailto,
                subject: $templateContact.subject,
                template: $templateContact.body.join(' ')
            });

            anchorEl.click();

            let sto = setTimeout(() => {
                clearTimeout(sto);
                anchorEl.remove();
            }, 1000);
        },

        sendNewsletter: (content) => {
            const $data = store.getters.data;

            const $templateNewsletter = $data.template.newsletter;

            const {email} = content;

            const anchorEl = document.createElement('a');

            anchorEl.href = generateHref({
                email,
                mailto: $templateNewsletter.mailto,
                subject: $templateNewsletter.subject,
                template: $templateNewsletter.body.join(' ')
            });

            anchorEl.click();

            let sto = setTimeout(() => {
                clearTimeout(sto);
                anchorEl.remove();
            }, 1000);
        }
    }
}