import {createStore} from 'vuex'

export default createStore({
    state: {
        data: {},
        config: {},
        treatments: {}
    },
    getters: {
        data: (state) => state.data || {},
        config: (state) => state.config || {},
        treatments: (state) => state.treatments || {},
    },
    mutations: {
        setData(state, payload) {
            state.data = payload.data;
        },
        setConfig(state, payload) {
            state.config = payload.config;
        },
        setTreatments(state, payload) {
            state.treatments = payload.treatments;
        }
    },
})
