import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/:pathMatch(.*)',
        redirect: {name: 'home'}
    },
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
    },
    {
        path: '/prices',
        name: 'prices',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "prices" */ '../views/PricesView.vue')
    }
]

const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(),
    routes,

    scrollBehavior(to, from, savedPosition) {
        console.log({
            to,
            from,
            savedPosition
        })
        if (to.hash) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({
                        el: to.hash,
                        top: 0,
                        behavior: 'smooth',
                    })
                }, 1000)
            })
        }
    },
})

export default router
