<template>
    <template v-if="$attrs.to">
        <router-link
                :to="$attrs.to"
                class="
                relative flex px-8 py-1.5 md:py-4 uppercase
                after:block after:content-[''] after:absolute after:h-[3px] after:bg-color-wewak after:w-full after:transition after:duration-300 after:origin-left after:bottom-0 after:left-0
                after:scale-x-0 after:hover:scale-x-100
            ">
            <slot></slot>
        </router-link>
    </template>

    <template v-else-if="$attrs.href">
        <a
                :href="$attrs.href"
                class="
                relative flex px-8 py-4 uppercase
                after:block after:content-[''] after:absolute after:h-[3px] after:bg-color-wewak after:w-full after:transition after:duration-300 after:origin-left after:bottom-0 after:left-0
                after:scale-x-0 after:hover:scale-x-100
            ">
            <slot></slot>
        </a>
    </template>


    <template v-else>
        <a
                class="
                relative flex px-8 py-4 uppercase
                after:block after:content-[''] after:absolute after:h-[3px] after:bg-color-wewak after:w-full after:transition after:duration-300 after:origin-left after:bottom-0 after:left-0
                after:scale-x-0 after:hover:scale-x-100
            ">
            <slot></slot>
        </a>
    </template>


</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>