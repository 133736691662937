<template>
  <div
    v-if="cookie && !accepts_cookies"
    class="fixed flex flex-col justify-center items-center p-2 md:p-4 rounded-lg bottom-2 left-2 w-4/5 max-w-sm h-auto min-h-10 z-50 border border-color-wewak bg-white shadow-2xl"
  >
    <img src="@/assets/images/cookie.png" class="w-14 my-2" />

    <h3 class="text-xl font-bold my-1"  v-html="cookie.title"></h3>

    <p class="text-sm text-center my-1"  v-html="cookie.text">
      
    </p>

    <button
      class="bg-color-wewak min-w-[150px] px-4 py-1 text-white uppercase rounded-md"
      v-html="cookie.buttonAccept"
      @click="handleClickAcceptCookies"
    > 
    </button>
  </div>
</template>

<script> 
import { useData } from "@/helpers/data";



export default{
    data:function(){
        return {
            accepts_cookies : false,
            data: null,
            cookie: null,
        }
    },
    methods:{
        handleClickAcceptCookies : function(){
            const vm = this;
            vm.accepts_cookies = true;
            window.localStorage.setItem('accepts_cookies', vm.accepts_cookies);
        }
    },
    mounted(){
        const vm = this;

        const data = useData().get();
        console.log(data);
        vm.cookie = data.cookie;

        let accepts_cookies = (window.localStorage.getItem('accepts_cookies'));
        if(!accepts_cookies){ accepts_cookies = "false" };
        vm.accepts_cookies = accepts_cookies == "true"; 
    }
} 
</script>

<style lang="scss" scoped></style>
