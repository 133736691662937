<template>
    <a 
    v-if="md.mobile()"
    aria-label="Chat on WhatsApp" 
    :href=" 'https://wa.me/' + whatsapp || '' "
    class="fixed bottom-5 right-2   w-14 aspect-square overflow-hidden z-20"
    > 
 
        <img alt="Chat on WhatsApp" src="@/assets/images/whatsapp-icon.svg" />
    </a>
</template>

<script setup>
   import {useData} from "@/helpers/data"; 
    const $data = useData().get();

    const whatsapp = ($data.sections.contact.whatsapp).replaceAll(' ',''); 

    const MobileDetect = require('mobile-detect');
    const md = new MobileDetect(window.navigator.userAgent);
</script>

<style lang="scss" scoped>

</style>