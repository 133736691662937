<template>
    <button class="w-16 h-16 bottom-32 right-1 fixed flex justify-center items-center z-40"

            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor-placement="top-center"

            @click.stop.prevent="scrollUp()"
    >
        <img src="@/assets/images/chevron-up-circle.svg" class="w-full"/>
    </button>
</template>

<script>
export default {
methods:{
    scrollUp(){
        window.scrollTo({
            top:0,
            left:0,
            x:0,
            y:0,
            behavior:"smooth"
        });
    }
}
}
</script>

<style lang="scss" scoped>

</style>