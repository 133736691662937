<template>
    <footer class="bg-gray-900 text-white py-8 px-4">
        <div class="container relative flex flex-row justify-center items-center border-b">
            <ul class="w-full flex flex-col md:flex-row gap-4 md:gap-10 justify-center items-center mb-4">

                <li>
                    <a class="uppercase hover:text-color-wewak" href="libs/pdfjs-2.2.228/web/viewer.html?file=../../../documents/impressum.pdf" target="_blank">
                        impressum
                    </a>
                </li>
                <li>
                    <a class="uppercase hover:text-color-wewak" href="libs/pdfjs-2.2.228/web/viewer.html?file=../../../documents/agb-und-datenschutz.pdf" target="_blank">
                        agb und datenschutz
                    </a>
                </li>
            </ul>
        </div>
        <div class="container relative flex flex-row justify-center items-center mt-4">
            <span class="mr-2">&copy; 2023</span>
            <span class="text-color-wewak">SkinFactory by Laura</span>
        </div>
    </footer>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>