<template>
    <h2 class="w-full text-[clamp(60px,10vw,120px)] font-family-barnett text-color-wewak leading-none" data-aos="fade-down">
        <slot></slot>
    </h2>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>

</style>