<template>
    <h2  class=" w-full uppercase font-family-century text-color-black text-center ">
        <slot></slot>
    </h2>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>

</style>