import './assets/style/tailwind.css'
import "./assets/style/main.scss";

import AOS from 'aos';
import 'aos/dist/aos.css';

import {createApp} from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'


const Application = createApp(App);
window.Application = Application;

Application
    .use(store)
    .use(router);

Application.component('SectionTitle', require('@/components/partials/SectionTitle.vue').default);
Application.component('SectionTitleBarnett', require('@/components/partials/SectionTitleBarnett.vue').default);
Application.component('SectionTitleCentury', require('@/components/partials/SectionTitleCentury.vue').default);
Application.component('SectionTitleCenturyNoSize', require('@/components/partials/SectionTitleCenturyNoSize.vue').default);

window.addEventListener('DOMContentLoaded', () => {
    Promise
        .all([
            fetch('./data.json'),
            fetch('./config.json'),
            fetch('./treatments.json'),
            fetch('./track/api.php'),
        ])
        .then(async ([response_data, response_config, response_treatments, track_api]) => {
            const data = await response_data.json();
            const config = await response_config.json();
            const treatments = await response_treatments.json();

            return {data, config, treatments}
        })
        .then((response) => {
            store.commit('setData', {data: response.data || {}});
            store.commit('setConfig', {config: response.config || {}});
            store.commit('setTreatments', {treatments: response.treatments || {}});
        })
        .then(() => {
            Application.mount('#app');
        }).then(() => {
            AOS.init();
        })
        .catch((err) => {
            console.error(err);
        });
})
