<template>
  <header class="py-4">
    <div class="px-4 md:px-0 flex _flex-col _md:flex-row justify-between mb-2">
      <!-- social -->
      <div class="w-full md:w-1/3 _order-3 _md:order-1">
        <ul
          class="flex flex-row justify-start md:justify-start items-center gap-2 h-full w-full"
        >
          <template v-for="(social, idx) in $contact.social">
            <template v-if="idx === 'instagram'">
              <li>
                <a
                  target="_blank"
                  :href="
                    'https://www.instagram.com/' + $contact.social.instagram
                  "
                  class="bg-color-wewak text-color-white w-8 h-8 md:w-10 md:h-10 flex justify-center items-center rounded-full text-lg md:text-2xl leading-none"
                >
                  <span class="icon-instagram1 ml-0.5"></span>
                </a>
              </li>
            </template>
            <template v-if="idx === 'email'">
              <li>
                <a
                  target="_blank"
                  :href="'mailto:' + $contact.social.email"
                  class="bg-color-wewak text-color-white w-8 h-8 md:w-10 md:h-10 flex justify-center items-center rounded-full text-lg md:text-2xl leading-none"
                >
                  <span class="icon-envelope"></span>
                </a>
              </li>
            </template>
          </template>
        </ul>
      </div>

      <!-- contact -->
      <div class="w-full md:w-2/3 _order-2 _md:order-3">
        <div
          class="h-full w-full flex flex-row md:flex-row justify-end md:justify-end items-center md:items-start xl:items-start gap-4"
        >
          <a
            :href="`tel:${$contact.phone}`"
            target="_blank"
            class="flex flex-row items-center w-auto"
          >
            <span
              class="icon-telephone-call text-2xl text-color-wewak mr-1"
            ></span>
            <span
              v-html="$contact.phone"
              class="text-sm hidden md:block"
            ></span>
          </a>

          <a
            :href="`${$contact.googleMapLink}`"
            target="_blank"
            class="flex flex row w-auto"
          >
            <span class="icon-location text-2xl text-color-wewak mr-1"></span>
            <span
              v-html="$contact.address"
              class="text-sm hidden md:block"
            ></span>
          </a>
        </div>
      </div>
    </div>

    <div
      class="px-4 md:px-0 flex flex-row justify-center"
      id="header_background"
    >
      <!-- logo -->
      <div class="w-full md:w-1/3 order-1 md:order-2">
        <div class="h-full w-full flex flex-row justify-center items-center">
          <img src="@/assets/images/logo.png" class="max-h-[100px]" />
        </div>
      </div>
    </div>

    <nav class="border-t border-gray-300 mt-4 px-4 md:px-0">
      <ul class="flex flex-col md:flex-row justify-center items-center w-full">
        <li>
          <header-menu-link to="/"> 
            home 
          </header-menu-link>
        </li>
        <li>
          <header-menu-link to="/#about-me"> 
            über mich
          </header-menu-link>
        </li>
        <li>
          <header-menu-link to="/prices">
            behandlungen & preise
          </header-menu-link>
        </li>
        <li>
          <header-menu-link :to="'/#contact'"> 
            kontakt 
          </header-menu-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script setup>
import { useData } from "@/helpers/data";
import HeaderMenuLink from "@/components/partials/HeaderMenuLink.vue";

const $data = useData().get();
const $contact = $data.sections.contact;
</script>

<style lang="scss" scoped></style>
